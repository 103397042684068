import axios from 'axios';

const controller = new AbortController();
const domain = process.env.VUE_APP_DOMAIN;
let token = localStorage.getItem('token');
const http = axios.create({
  baseURL: `${domain}/api/graphql`,
});

// 檢查 token 效期
function checkTokenValid() {
  return axios.post(`${domain}/api/graphql`, {
    query: `
      query{
        loginRoot{
          checkToken
        }
      }
    `,
  }, {
    headers: { Authorization: `Bearer ${token}` },
    signal: controller.signal,
  }).then(({ data }) => data.data.loginRoot.checkToken);
}

// 攔截器 [NEED REFACTOR]: toke 錯誤重新導向需改良
http.interceptors.request.use(
  async (config) => {
    // 檢查是否有 token
    if (token === null) {
      window.location.href = `${domain}/`;
      return new Error('token is null');
    }

    // 設定 abort
    config.signal = controller.signal;

    const isTokenValid = await checkTokenValid();
    // 檢查 token 有效性
    if (!isTokenValid) {
      controller.abort();
      alert('登入逾期，請重新登入');
      localStorage.removeItem('token');
      token = null;
      window.location.href = `${domain}/`;
      return new Error('token is expired');
    }

    config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line
    return config;
  },
  (error) => Promise.reject(error),
);

http.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response),
);

// 通用請求(帶 token)
export function POST(params) {
  try {
    return http.post('', params).then(({ data }) => data.data);
  } catch (error) {
    return Promise.reject(error);
  }
}
// 通用請求(不帶 token)
export function POST_WITHOUT_TOKEN(config) {
  try {
    return axios.post(`${domain}/api/graphql`, config).then(({ data }) => data.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// 其他不帶 token 的請求
export function LOGIN(formData) {
  try {
    const response = axios.post(`${domain}/api/graphql`, {
      query: `
        mutation DataQuery($login:LoginInput!){
          mLoginRoot{
            login(login:$login){
              token
              message
            }
          }
        }
      `,
      variables: { login: formData },
    }).then(({ data }) => {
      const res = data.data.mLoginRoot.login;
      token = res.token;
      return res;
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// 上傳檔案 FormData
export function UPLOADFILE(formData, path = '', fieldName) {
  try {
    return axios({
      method: 'post',
      url: `${domain}/api/file${path}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }).then(({ data }) => ({
      fieldName,
      ...data,
    }));
  } catch (error) {
    return Promise.reject(error);
  }
}

export function GETFILE(config) {
  const { type, filenum, id } = config;
  return `${domain}/api/file?type=${type}&filenum=${filenum}&id=${id}`;
}

export function CLIENT_IP() {
  return axios.get('https://api.ipify.org?format=json').then(({ data }) => data.ip);
}
