import _ from 'lodash';
import objectFormatter from '@/utils/objectFormatter';
import uploadFields from './uploadField.json';
import {
  LOGIN,
  POST,
  UPLOADFILE,
  GETFILE,
  POST_WITHOUT_TOKEN,
  CLIENT_IP,
} from './http';

const api = {
  // ===登入前操作(註冊,忘記密碼)
  signup(formData) {
    // 註冊暫時會員
    return POST_WITHOUT_TOKEN({
      query: `
        mutation DataQuery($member:MemberInput){
          mTMCMemberRoot{
            memberJoin(member:$member)
          }
        }`,
      variables: {
        member: formData,
      },
    }).then((data) => data.mTMCMemberRoot.memberJoin);
  },
  // 刪除暫時會員(用於上傳發生錯誤)
  deleteSignup(formData) {
    return POST_WITHOUT_TOKEN({
      query: `
        mutation DataQuery($memberId:Int!, $msg:String){
          mTMCMemberRoot{
            memberRemove(memberId:$memberId, msg:$msg)
          }
        }`,
      variables: formData,
    }).then((data) => data.mTMCMemberRoot.memberRemove);
  },
  uploadFilesToSignup(files, userId, unitType) {
    const formattedFiles = _.chain(files)
      .mapKeys((val, key) => key.slice(-1))
      .value();

    console.log(formattedFiles);
    function promiseCreator(file, fileType) {
      const fd = new FormData();
      fd.append('memberId', userId);
      fd.append('fileType', fileType);
      fd.append('file', file);

      return UPLOADFILE(fd, '', _.get(uploadFields, `/.unitType${unitType}.file${fileType}`));
    }

    return Promise.all(_.map(formattedFiles, (file, key) => promiseCreator(file, Number(key))));
  },
  uploadFilesToRent(files, config) {
    const formattedFiles = _.chain(files)
      // keyName 只保留數字(用於上傳 fileType)
      .mapKeys((val, key) => key.slice(-1))
      // 去除空值與字串(只有 file 要上傳)
      .omitBy((val) => !val || _.isString(val))
      .value();

    function promiseCreator(file, fileType) {
      const fd = new FormData();
      fd.append('file', file);
      fd.append('id', config.id);
      fd.append('uploadType', config.uploadType);
      fd.append('fileType', fileType);
      fd.append('Authorization', config.Authorization);

      return UPLOADFILE(fd, '/rental', _.get(uploadFields, `/rental.siteId${config.uploadType}.file${fileType}`));
    }

    return Promise.all(_.map(formattedFiles, (file, key) => promiseCreator(file, Number(key))));
  },
  checkIdNumber(id) {
    return POST_WITHOUT_TOKEN({
      query: `
      query
        {
          tMCMemberRoot{
            checkIdNo(idNo:"${id}")
          }
        }`,
    }).then((data) => data.tMCMemberRoot.checkIdNo);
  },
  // 暫時會員轉正式會員
  switchUserStatus(formData) {
    return POST_WITHOUT_TOKEN({
      query: `mutation DataQuery($memberId:Int!){
        mTMCMemberRoot{
          memberComplete(memberId:$memberId)
        }
      }`,
      variables: formData,
    }).then((data) => data.mTMCMemberRoot.memberComplete);
  },
  verifyMailSend(config) {
    return POST_WITHOUT_TOKEN({
      query: `
      mutation DataQuery($email:String,$id:Int!){
        mTMCMemberRoot{
          memberSendVerifyMail(email:$email,memberId:$id)
        }
      }
      `,
      variables: config,
    })
      .then((data) => data.mTMCMemberRoot.memberSendVerifyMail);
  },
  verifyCodeCheck(config) {
    return POST_WITHOUT_TOKEN({
      query: `
      mutation DataQuery($id:Int!,$code:String){
        mTMCMemberRoot{
          memberCheckVerifyCode(verifyCode:$code,memberId:$id)
        }
      }
      `,
      variables: config,
    })
      .then((data) => data.mTMCMemberRoot.memberCheckVerifyCode);
  },
  async forgotPasswordMailSend(formData) {
    const config = {
      number: formData.username,
      email: formData.email,
    };
    return POST_WITHOUT_TOKEN({
      query: `
        mutation DataQuery($email: String, $number: String!) {
          mTMCMemberRoot {
            memberSendChangePwdMail(email: $email, number: $number)
          }
        }`,
      variables: config,
    }).then((data) => data.mTMCMemberRoot.memberSendChangePwdMail === 1);
  },
  forgotPasswordCodeCheck(token) {
    const config = { code: token };
    return POST_WITHOUT_TOKEN({
      query: `
        mutation DataQuery($code: String) {
          mTMCMemberRoot {
            memberCheckPwdCode(code: $code)
          }
        }`,
      variables: config,
    }).then((data) => data.mTMCMemberRoot.memberCheckPwdCode === 1);
  },
  async forgotPasswordUpdatePassword(formData) {
    const config = {
      code: formData.tempToken,
      pwd: formData.password,
      ip: await CLIENT_IP(),
    };
    return POST_WITHOUT_TOKEN({
      query: `
        mutation DataQuery($code: String, $pwd: String, $ip: String) {
          mTMCMemberRoot {
            memberForgetChangePwd(code: $code, pwd: $pwd, ip: $ip)
          }
        }`,
      variables: config,
    }).then((data) => data.mTMCMemberRoot.memberForgetChangePwd === 1);
  },
  // 登入登出
  login(formData) {
    const user = {
      at: formData.username,
      pd: formData.password,
      loginType: '',
    };
    return LOGIN(user);
  },
  // 驗證碼
  getCaptcha() {
    return POST_WITHOUT_TOKEN({
      query: `
        query {
          capthcaRoot {
            getCaptcha {
              key
              value
            }
          }
        }`,
    }).then((data) => data.capthcaRoot.getCaptcha);
  },
  checkCaptcha(config) {
    return POST_WITHOUT_TOKEN({
      query: `
        mutation DataMutation($captchaInput: CaptchaInput) {
          mCaptchaRoot {
            checkCaptcha(inputCaptcha: $captchaInput)
          }
        }`,
      variables: {
        captchaInput: config,
      },
    }).then((data) => data.mCaptchaRoot.checkCaptcha);
  },
  // ===會員資料
  getUserData() {
    return POST({
      query: `
        query {
          tMCMemberRoot {
            memberData {
              id
              unitType
              unitName
              unNumber
              headerName
              name
              iDNumber
              contactName
              contactEmail
              contactTel
              contactMobile
              contactFax
              address
              otherName
              otherEmail
              otherTel
              otherMobile
            }
          }
        }`,
    }).then((data) => data.tMCMemberRoot.memberData);
  },
  updateUserData(formData) {
    return POST({
      query: `
        mutation DataQuery($member:MemberInput){
          mTMCMemberRoot{
            memberModify(member:$member)
          }
        }`,
      variables: {
        member: formData,
      },
    }).then((data) => data.mTMCMemberRoot.memberModify);
  },
  async updateUserPassword(formData) {
    const config = {
      oriPassword: formData.oldPassword,
      password: formData.password,
      ip: await CLIENT_IP(),
    };
    return POST({
      query: `
      mutation DataQuery($password: String, $ip: String, $oriPassword: String) {
        mTMCMemberRoot {
          memberPwdChange(pwd: $password, ip: $ip, oriPwd: $oriPassword)
        }
      }`,
      variables: config,
    }).then((data) => data.mTMCMemberRoot.memberPwdChange === 1);
  },
  // 繳費通知
  getUserNotifications() {
    return POST({
      query: `
        query {
          tMCMemberRoot{
            notification{
              chargeName
              expectedDate
              number
            }
          }
        }`,
    }).then((data) => objectFormatter.eventsFormatter(data.tMCMemberRoot.notification));
  },
  // ===會員活動(event)
  getUserEvents(isPassedOnly = false) {
    return POST({
      query: `
        query DataQuery(
          $st: DateOnly
          $et: DateOnly
          $keyword: String
          $siteId: Int!
          $isDesc: Boolean!
          $forCalendar: Boolean!
        ) {
          tMCRentalRoot {
            rentalList(
              siteId: $siteId
              keyword: $keyword
              isDesc: $isDesc
              st: $st
              et: $et
              forCalendar: $forCalendar
            ) {
              id
              numNo
              siteId
              startTime
              endTime
              createTime
              activityNameZh
              isConfirm
              isSec1Type
              isSec2Type
              picture
              url
              notification {
                chargeName
                expectedDate
              }
            }
          }
        }`,
      variables: {
        siteId: 0,
        isDesc: true,
        forCalendar: isPassedOnly,
      },
    }).then((data) => objectFormatter.eventsFormatter(data.tMCRentalRoot.rentalList));
  },
  getUserEventNotification(config) {
    return POST({
      query: `
        query DataQuery($siteId:Int!,$rentalId:Int!){
          tMCRentalRoot{
            receiptListByRental(siteId:$siteId,rentalId:$rentalId){
              chargeName
              expectedAmount
              receiveDate
              expectedDate
            }
          }
        }`,
      variables: {
        siteId: config.siteId,
        rentalId: config.id,
      },
    }).then((data) => data.tMCRentalRoot.receiptListByRental);
  },
  // 行事曆活動資訊
  async getSystemEvents(config) {
    const events = await POST_WITHOUT_TOKEN({
      query: `
        query DataQuery($st: DateOnly!, $et: DateOnly!, $siteId: Int!) {
          tMCCalendarRoot {
            getCalendars(st: $st, et: $et, siteId: $siteId) {
              date
              isOpen
              title
              isDuringApplication
            }
          }
        }`,
      variables: config,
    }).then((data) => data.tMCCalendarRoot.getCalendars);
    return objectFormatter.eventsFormatter(events);
  },
  async getConcertEvents(config) {
    const systemEvents = await this.getSystemEvents({
      st: config.startDate,
      et: config.endDate,
      siteId: 1,
    });

    const events = await POST_WITHOUT_TOKEN({
      query: `
        query DataQuery($st: DateOnly!, $et: DateOnly!) {
          tMCCalendarRoot {
            getRentalList(st: $st, et: $et) {
              id
              startTime
              endTime
              activityNameZh
              area
              picture
              url
            }
          }
        }`,
      variables: {
        st: config.startDate,
        et: config.endDate,
      },
    }).then((data) => data.tMCCalendarRoot.getRentalList);

    return { systemEvents, events: objectFormatter.eventsFormatter(events) };
  },
  async getCulturalEvents(config) {
    const systemEvents = await this.getSystemEvents({
      st: config.startDate,
      et: config.endDate,
      siteId: 2,
    });

    const events = await POST_WITHOUT_TOKEN({
      query: `
        query DataQuery($st: DateOnly!, $et: DateOnly!) {
          tMCCalendarRoot {
            getRentalCulList(st: $st, et: $et) {
              id
              startTime
              endTime
              activityNameZh
              rentRange
              picture
              url
            }
          }
        }`,
      variables: {
        st: config.startDate,
        et: config.endDate,
      },
    }).then((data) => data.tMCCalendarRoot.getRentalCulList);

    return { systemEvents, events: objectFormatter.eventsFormatter(events) };
  },
  // ===會員案件(eventForm)
  // 表演廳
  userConcertEvent: {
    async get(id) {
      const eventForm = await POST({
        query: `
          query DataQuery($id: Int!) {
            tMCRentalRoot {
              getRental(rentalId: $id) {
                id
                numNo
                isConfirm
                cMId
                createTime
                activityNameZh
                activityNameEn
                activityForm
                activityType
                area
                ticketing
                startTime
                endTime
                showStartTime
                showEndTime
                fileName
                fileName1
                fileName2
                sessionList1: showDay(choice: 1) {
                  choice
                  showDate
                  sessions
                }
                entryExitsList1: entryExit(choice: 1) {
                  choice
                  entryExitDate
                  timeDuration
                }
                startTime1
                endTime1
                showStartTime1
                showEndTime1
                sessionList2: showDay(choice: 2) {
                  choice
                  showDate
                  sessions
                }
                entryExitsList2: entryExit(choice: 2) {
                  choice
                  entryExitDate
                  timeDuration
                }
                actContactName
                actContactEmail
                actContactTel
                actContactMobile
                actContactAddress
                otherName
                otherEmail
                otherTel
                otherMobile
              }
            }
          }`,
        variables: { id },
      })
        .then((data) => objectFormatter.eventFormFormatter(data.tMCRentalRoot.getRental, 1));

      eventForm.file1 = eventForm.file1 ? GETFILE({ type: 1, filenum: 1, id }) : null;
      eventForm.file2 = eventForm.file2 ? GETFILE({ type: 1, filenum: 2, id }) : null;
      eventForm.file3 = eventForm.file3 ? GETFILE({ type: 1, filenum: 3, id }) : null;

      return eventForm;
    },
    create(formData, isSaveAsDraft = false) {
      const formattedFormData = objectFormatter.eventFormFormatter(formData, 1, true);
      // 草稿或送出
      const qlInput = isSaveAsDraft ? 'RentalDraftInput' : 'RentalInput';
      const qlMethod = isSaveAsDraft ? 'rentalDraftInsert' : 'rentalInsert';
      return POST({
        query: `
          mutation DataQuery($rental: ${qlInput}!) {
            mTMCRentalRoot {
              ${qlMethod}(rental: $rental) {
                _OutInt
                _OutString
              }
            }
          }`,
        variables: { rental: formattedFormData },
      }).then((data) => {
        const { _OutInt: id, _OutString: numNo } = data.mTMCRentalRoot[qlMethod];
        return { id, numNo };
      });
    },
    update(formData, isSaveAsDraft = false) {
      const formattedFormData = objectFormatter.eventFormFormatter(formData, 1, true);
      // 草稿或送出
      const qlInput = isSaveAsDraft ? 'RentalDraftInput' : 'RentalInput';
      const qlMethod = isSaveAsDraft ? 'rentalDraftUpdate' : 'rentalUpdate';

      return POST({
        query: `
          mutation DataQuery($rental: ${qlInput}!) {
            mTMCRentalRoot {
              ${qlMethod}(rental: $rental) {
                _OutInt
                _OutString
              }
            }
          }`,
        variables: { rental: formattedFormData },
      }).then((data) => {
        const { _OutInt: id, _OutString: numNo } = data.mTMCRentalRoot[qlMethod];
        return { id, numNo };
      });
    },
    delete(id) {
      return POST({
        query: `
          mutation DataQuery($id: Int!) {
            mTMCRentalRoot {
              rentalDelete(id: $id)
            }
          }`,
        variables: { id },
      }).then((data) => data.mTMCRentalRoot.rentalDelete);
    },
  },
  // 文化館
  userCulturalEvent: {
    async get(id) {
      const eventForm = await POST({
        query: `
          query DataQuery($id:Int!){
            tMCRentalRoot {
              getRentalCul(rentalId: $id) {
                id
                accountId
                activityNameZh
                activityNameEn
                proSource
                activityType
                rentRange
                ticketing
                personCount
                startTime
                endTime
                typeStartTime
                typeEndTime
                typeCount1
                typeStatus1
                startTime1
                endTime1
                typeStartTime1
                typeEndTime1
                typeCount2
                typeStatus2
                fileName
                fileName1
                contactName
                contactEmail
                contactTel
                contactMobile
                contactAddress
                otherName
                otherEmail
                otherTel
                otherMobile
                number
                status
              }
            }
          }`,
        variables: { id },
      })
        .then((data) => objectFormatter.eventFormFormatter(data.tMCRentalRoot.getRentalCul, 2));

      eventForm.file1 = eventForm.file1 ? GETFILE({ type: 2, filenum: 1, id }) : null;
      eventForm.file2 = eventForm.file2 ? GETFILE({ type: 2, filenum: 2, id }) : null;

      return eventForm;
    },
    create(formData, isSaveAsDraft = false) {
      const formattedFormData = objectFormatter.eventFormFormatter(formData, 2, true);
      // 草稿或送出
      const qlInput = isSaveAsDraft ? 'RentalCulDraftInput' : 'RentalCulInput';
      const qlMethod = isSaveAsDraft ? 'rentalCulDraftInsert' : 'rentalCulInsert';
      return POST({
        query: `
          mutation DataQuery($rentalCul: ${qlInput}) {
            mTMCRentalRoot {
              ${qlMethod}(rentalCul: $rentalCul){
                _OutInt
                _OutString
              }
            }
          }`,
        variables: { rentalCul: formattedFormData },
      }).then((data) => {
        const { _OutInt: id, _OutString: numNo } = data.mTMCRentalRoot[qlMethod];
        return { id, numNo };
      });
    },
    update(formData, isSaveAsDraft = false) {
      const formattedFormData = objectFormatter.eventFormFormatter(formData, 2, true);
      // 草稿或送出
      const qlInput = isSaveAsDraft ? 'RentalCulDraftInput' : 'RentalCulInput';
      const qlMethod = isSaveAsDraft ? 'rentalCulDraftUpdate' : 'rentalCulUpdate';
      return POST({
        query: `
          mutation DataQuery($rentalCul: ${qlInput}) {
            mTMCRentalRoot {
              ${qlMethod}(rentalCul: $rentalCul){
                _OutInt
                _OutString
              }
            }
          }`,
        variables: { rentalCul: formattedFormData },
      }).then((data) => {
        const { _OutInt: id, _OutString: numNo } = data.mTMCRentalRoot[qlMethod];
        return { id, numNo };
      });
    },
    delete(id) {
      return POST({
        query: `
          mutation DataQuery($id: Int!) {
            mTMCRentalRoot {
              rentalCulDelete(id: $id)
            }
          }`,
        variables: { id },
      }).then((data) => data.mTMCRentalRoot.rentalCulDelete);
    },
  },
  // 檢查是否為可申請的時段
  rentalDurationValidate(config) {
    return POST({
      query: `
        query DataQuery($st: DateOnly!, $et: DateOnly!, $siteId: Int!, $scheduleAttribute: String) {
          tMCRentalRoot {
            checkValidate(
              st: $st,
              et: $et,
              siteId: $siteId,
              scheduleAttribute: $scheduleAttribute
            )
          }
        }`,
      variables: {
        st: config.startDate,
        et: config.endDate,
        siteId: config.siteId,
        scheduleAttribute: config.activitySessionType,
      },
    }).then((data) => ({
      fieldName: config.fieldName,
      isValid: data.tMCRentalRoot.checkValidate,
    }));
  },
  // ===申請表相關雜項
  // datepicker 資訊
  async getDatepickerEvents(config) {
    const events = await POST_WITHOUT_TOKEN({
      query: `
        query DataQuery($st: DateOnly!, $et: DateOnly!, $siteId: Int!, $rentRange: String, $scheduleAttribute: String) {
          tMCCalendarRoot {
            getApplyCalendars(
              st: $st,
              et: $et,
              siteId: $siteId,
              rentRange: $rentRange,
              scheduleAttribute: $scheduleAttribute
            ) {
              date
              isOpen
              title
              isDuringApplication
            }
          }
        }`,
      variables: {
        st: config.startDate,
        et: config.endDate,
        siteId: config.siteId,
        rentRange: config.rentRange,
        scheduleAttribute: config.activitySessionType,
      },
    }).then((data) => data.tMCCalendarRoot.getApplyCalendars);
    return objectFormatter.eventsFormatter(events);
  },
  // 表單選項
  getConcertFormOption() {
    return POST({
      query: `
        query{
          tMCRentalRoot{
            activitySessionType:getParameters(type:"ActType"){
              id
              name
            }

            activityArea:getParameters(type:"Area"){
              id
              name
            }
            activityType:getParameters(type:"FormType"){
              id
              name
            }
            activityCharge:getParameters(type:"Ticketing"){
              id
              name
            }
          }
        }
      `,
    }).then((data) => data.tMCRentalRoot);
  },
  getConcertTimeSelectOption(applyDate) {
    return POST({
      query: `
        query DataQuery($applyDate: DateOnly!) {
          tMCRentalRoot {
            getPeriodList(applyDate: $applyDate) {
              pmId
              pmText
            }
          }
        }
      `,
      variables: {
        applyDate,
      },
    }).then((data) => data.tMCRentalRoot.getPeriodList);
  },
  getCulturalFormOption() {
    return POST({
      query: `
        query{
          tMCRentalRoot{
            activityType:getParameters(type:"CulActType"){
              id
              name
            }
            activitySource:getParameters(type:"ProSource"){
              id
              name
            }
            activityArea:getParameters(type:"RentRange"){
              id
              name
            }
            activityCharge:getParameters(type:"Ticketing"){
              id
              name
            }
          }
        }
      `,
    }).then((data) => data.tMCRentalRoot);
  },
  // 試算費率表
  getCulturalRentRate(config) {
    return POST({
      query: `
        query DataQuery($area:String){
          tMCRentalRoot{
            getChargeCul(areaId:$area){
              cMId
              areaId
              entryExit
              showDay
              under30
              under90
              above90
            }
          }
        }`,
      variables: {
        area: config.area,
      },
    }).then((data) => data.tMCRentalRoot.getChargeCul);
  },
  getConcertRentRate(config) {
    return POST({
      query: `
        query DataQuery($scheduleAttribute: String!, $createTime: DateTime!){
          tMCRentalRoot{
            getCharge(scheduleAttribute: $scheduleAttribute, createTime: $createTime){
              id
              cMId
              chargeType
              elcAir
              service
              occupy
              deposit
              periodFeeList {
                periodId
                periodText
                periodFee
              }
            }
          }
        }`,
      variables: {
        scheduleAttribute: config.sessionType,
        createTime: config.createTime || new Date().toISOString(), // 默認為當下時間
      },
    }).then((data) => data.tMCRentalRoot.getCharge);
  },
};

export default api;
